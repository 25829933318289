@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

html {
  background-color: #ffffff'';
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  text-align: center;
}

body, #root, .page-wrapper {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  min-height: 100%;
  background-color: #ffffff;
}

.Demo__map-maker-icon {
  color: #FD6C6C;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.Demo__search-bar-container {
  width: 90%;
  max-width: 500px;
  margin: auto;
}

.Demo__search-input-container {
  position: relative;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.Demo__clear-button,
.Demo__clear-button:active,
.Demo__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.Demo__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.Demo__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}

.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Demo__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Demo__error-message {
  color: red;
}

.Demo__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein .75s;
    -moz-animation: fadein .75s;
    -ms-animation: fadein .75s;
    -o-animation: fadein .75s;
    animation: fadein .75s;
  }

  .btn {
    margin-top: 1.5rem;
  }

  .form-group {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    width: 50%;
  }

  path {
    pointer-events: all;
  }
  path:hover {
    opacity: 0.50;
    cursor: pointer;
  }

}